import _types from "./types";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.required = exports.enum = exports.null = exports.undefined = exports.omit = exports.pick = exports.partial = exports.lazy = exports.date = exports.tuple = exports.dictionary = exports.record = exports.literals = exports.intersection = exports.union = exports.array = exports.object = exports.literal = exports.unknown = exports.bigint = exports.number = exports.boolean = exports.string = exports.IntersectionType = exports.UnionType = exports.UnknownType = exports.DateType = exports.OptionalType = exports.NullableType = exports.TupleType = exports.ArrayType = exports.ObjectType = exports.NullType = exports.UndefinedType = exports.StringType = exports.BooleanType = exports.NumberType = exports.Type = exports.ValidationError = exports.keySignature = void 0;
const types_1 = _types;
const types_2 = _types;
var types_3 = _types;
Object.defineProperty(exports, "keySignature", {
  enumerable: true,
  get: function () {
    return types_3.keySignature;
  }
});
Object.defineProperty(exports, "ValidationError", {
  enumerable: true,
  get: function () {
    return types_3.ValidationError;
  }
});
Object.defineProperty(exports, "Type", {
  enumerable: true,
  get: function () {
    return types_3.Type;
  }
});
// Types
Object.defineProperty(exports, "NumberType", {
  enumerable: true,
  get: function () {
    return types_3.NumberType;
  }
});
Object.defineProperty(exports, "BooleanType", {
  enumerable: true,
  get: function () {
    return types_3.BooleanType;
  }
});
Object.defineProperty(exports, "StringType", {
  enumerable: true,
  get: function () {
    return types_3.StringType;
  }
});
Object.defineProperty(exports, "UndefinedType", {
  enumerable: true,
  get: function () {
    return types_3.UndefinedType;
  }
});
Object.defineProperty(exports, "NullType", {
  enumerable: true,
  get: function () {
    return types_3.NullType;
  }
});
Object.defineProperty(exports, "ObjectType", {
  enumerable: true,
  get: function () {
    return types_3.ObjectType;
  }
});
Object.defineProperty(exports, "ArrayType", {
  enumerable: true,
  get: function () {
    return types_3.ArrayType;
  }
});
Object.defineProperty(exports, "TupleType", {
  enumerable: true,
  get: function () {
    return types_3.TupleType;
  }
});
Object.defineProperty(exports, "NullableType", {
  enumerable: true,
  get: function () {
    return types_3.NullableType;
  }
});
Object.defineProperty(exports, "OptionalType", {
  enumerable: true,
  get: function () {
    return types_3.OptionalType;
  }
});
Object.defineProperty(exports, "DateType", {
  enumerable: true,
  get: function () {
    return types_3.DateType;
  }
});
Object.defineProperty(exports, "UnknownType", {
  enumerable: true,
  get: function () {
    return types_3.UnknownType;
  }
});
Object.defineProperty(exports, "UnionType", {
  enumerable: true,
  get: function () {
    return types_3.UnionType;
  }
});
Object.defineProperty(exports, "IntersectionType", {
  enumerable: true,
  get: function () {
    return types_3.IntersectionType;
  }
});
const string = opts => new types_2.StringType(opts);
exports.string = string;
const boolean = () => new types_2.BooleanType();
exports.boolean = boolean;
const number = opts => new types_2.NumberType(opts);
exports.number = number;
const bigint = opts => new types_2.BigIntType(opts);
exports.bigint = bigint;
const unknown = () => new types_2.UnknownType();
exports.unknown = unknown;
const literal = literal => new types_2.LiteralType(literal);
exports.literal = literal;
const object = (shape, opts) => new types_2.ObjectType(shape, opts);
exports.object = object;
const array = (schema, opts) => new types_2.ArrayType(schema, opts);
exports.array = array;
const union = (schemas, opts) => new types_2.UnionType(schemas, opts);
exports.union = union;
const intersection = (l, r) => l.and(r);
exports.intersection = intersection;
const literals = (...args) => new types_2.UnionType(args.map(exports.literal));
exports.literals = literals;
const record = schema => new types_2.ObjectType({
  [types_2.keySignature]: schema
});
exports.record = record;
const dictionary = schema => {
  if (schema instanceof types_2.OptionalType) {
    return new types_2.ObjectType({
      [types_2.keySignature]: schema
    });
  }
  return new types_2.ObjectType({
    [types_2.keySignature]: new types_2.OptionalType(schema)
  });
};
exports.dictionary = dictionary;
const tuple = schemas => new types_2.TupleType(schemas);
exports.tuple = tuple;
const date = () => new types_2.DateType();
exports.date = date;
const lazy = fn => new types_2.LazyType(fn);
exports.lazy = lazy;
function partial(schema, opts) {
  if (schema instanceof types_2.ObjectType) {
    return schema.partial(opts);
  }
  return new types_2.PartialType(schema, opts);
}
exports.partial = partial;
function pick(schema, keys) {
  return schema.pick(keys);
}
exports.pick = pick;
function omit(schema, keys) {
  return schema.omit(keys);
}
exports.omit = omit;
const undefinedValue = () => new types_2.UndefinedType();
exports.undefined = undefinedValue;
const nullValue = () => new types_2.NullType();
exports.null = nullValue;
const enumValue = (e, opts) => new types_2.EnumType(e, opts);
exports.enum = enumValue;
// Support default imports
exports.default = {
  Type: types_2.Type,
  string: exports.string,
  boolean: exports.boolean,
  number: exports.number,
  bigint: exports.bigint,
  unknown: exports.unknown,
  literal: exports.literal,
  literals: exports.literals,
  date: exports.date,
  object: exports.object,
  array: exports.array,
  union: exports.union,
  intersection: exports.intersection,
  record: exports.record,
  dictionary: exports.dictionary,
  tuple: exports.tuple,
  partial,
  pick,
  omit,
  required,
  lazy: exports.lazy,
  undefined: undefinedValue,
  null: nullValue,
  enum: enumValue,
  ValidationError: types_2.ValidationError,
  keySignature: types_2.keySignature,
  // types
  NumberType: types_2.NumberType,
  BooleanType: types_2.BooleanType,
  StringType: types_2.StringType,
  UndefinedType: types_2.UndefinedType,
  NullType: types_2.NullType,
  ObjectType: types_2.ObjectType,
  ArrayType: types_2.ArrayType,
  TupleType: types_2.TupleType,
  NullableType: types_2.NullableType,
  OptionalType: types_2.OptionalType,
  DateType: types_2.DateType,
  UnknownType: types_2.UnknownType,
  UnionType: types_2.UnionType,
  IntersectionType: types_1.IntersectionType
};
function required(schema) {
  if (schema instanceof types_2.NullableType) {
    return required(schema.required());
  }
  if (schema instanceof types_2.OptionalType) {
    return required(schema.required());
  }
  return schema;
}
exports.required = required;
export default exports;
export const __esModule = exports.__esModule,
  IntersectionType = exports.IntersectionType,
  UnionType = exports.UnionType,
  UnknownType = exports.UnknownType,
  DateType = exports.DateType,
  OptionalType = exports.OptionalType,
  NullableType = exports.NullableType,
  TupleType = exports.TupleType,
  ArrayType = exports.ArrayType,
  ObjectType = exports.ObjectType,
  NullType = exports.NullType,
  UndefinedType = exports.UndefinedType,
  StringType = exports.StringType,
  BooleanType = exports.BooleanType,
  NumberType = exports.NumberType,
  Type = exports.Type,
  ValidationError = exports.ValidationError,
  keySignature = exports.keySignature;
const _required = exports.required,
  _enum = exports.enum,
  _null = exports.null,
  _undefined = exports.undefined,
  _omit = exports.omit,
  _pick = exports.pick,
  _partial = exports.partial,
  _lazy = exports.lazy,
  _date = exports.date,
  _tuple = exports.tuple,
  _dictionary = exports.dictionary,
  _record = exports.record,
  _literals = exports.literals,
  _intersection = exports.intersection,
  _union = exports.union,
  _array = exports.array,
  _object = exports.object,
  _literal = exports.literal,
  _unknown = exports.unknown,
  _bigint = exports.bigint,
  _number = exports.number,
  _boolean = exports.boolean,
  _string = exports.string;
export { _required as required, _enum as enum, _null as null, _undefined as undefined, _omit as omit, _pick as pick, _partial as partial, _lazy as lazy, _date as date, _tuple as tuple, _dictionary as dictionary, _record as record, _literals as literals, _intersection as intersection, _union as union, _array as array, _object as object, _literal as literal, _unknown as unknown, _bigint as bigint, _number as number, _boolean as boolean, _string as string };